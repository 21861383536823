
// import "./BalanceOverview.css";

const BalanceOverview = () => {
  return ("")
  // return (
  //   <section className="balanceOverview">
  //     <Toaster position="bottom-right" reverseOrder={false} />
  //     <div className="oc_container">
  //       <CAccordion flush>
  //         <CAccordionItem itemKey={1}>
  //           <CAccordionHeader>
  //             <div className="title">
  //               <h2>HFG Balance Overview</h2>
  //               <p className="ms-auto m-0 p-2 viewbtn d-flex align-items-center">
  //                 View Details{" "}
  //                 <span className="arrow d-inline-block ms-1"> &#10095;</span>
  //               </p>
  //             </div>
  //           </CAccordionHeader>
  //           <CAccordionBody>
  //             <div className="balanceOverviewBox">
  //               <ul>
  //                 <li className="mainbalance">
  //                   <p>Your HFG Balance </p>
  //                   <strong>{localeFixed(myOcBalance) || 0} HFG</strong>
  //                 </li>
  //                 <li  className="mainbalance">
  //                   <p>Matic Invested </p>
  //                   <strong>
  //                     {localeFixed(totalMaticInvested) || 0}{" "}
  //                     {parseFloat(totalMaticInvested) > 0
  //                       ? `( $${localeFixed(
  //                           (parseFloat(usdInvested) -
  //                             parseFloat(usdtInvested)) /
  //                             (parseFloat(totalMaticInvested) === 0
  //                               ? 1
  //                               : parseFloat(totalMaticInvested))
  //                         )} )`
  //                       : ""}
  //                   </strong>
  //                 </li>
  //                 <li  className="mainbalance">
  //                   <p>USDT Invested </p>
  //                   <strong>${localeFixed(usdtInvested) || 0}</strong>
  //                 </li>
  //                 <li  className="mainbalance">
  //                   <p>Investment in USD </p>
  //                   <strong>${localeFixed(usdInvested) || 0}</strong>
  //                 </li>
  //                 <li  className="mainbalance">
  //                   <p>HFG Bought </p>
  //                   <strong>{localeFixed(totalOCAllotted) || 0} HFG</strong>
  //                 </li>

  //                 <li  className="mainbalance">
  //                   <p>Claim After </p>
  //                   <strong className="countDown">
  //                     {/* 27 March 06:29 PM GMT */}

  //                     <Countdown date={1679951004 * 1000} />
  //                   </strong>
  //                 </li>
  //               </ul>
  //               <div className="buttonGrp">
  //                 <button className="oc_btn" onClick={ClaimOC}>
  //                   {chekClaim}
  //                 </button>
  //               </div>
  //             </div>
  //             <div className="row">
  //               <div className="col-12 col-md-6">
  //                 <div className="d-flex mt-4 mt-md-5 mb-3">
  //                   <div className="logobox text-white">
  //                     <img
  //                       className="profilePic"
  //                       src={profilePic}
  //                       alt=""
  //                       width="56"
  //                       height="56"
  //                     />
  //                   </div>
  //                   <div className="achieveinfo ms-3 ms-xl-0 text-white">
  //                     <h6>Achievement</h6>
  //                     <p>
  //                       Lorem ipsum dolor, sit amet consectetur adipisicing
  //                       elit. Ex?
  //                     </p>
  //                   </div>
  //                 </div>
  //                   <div className="socialicons">
  //                     <ul>
  //                       <li>
  //                         <a
  //                           href="https://t.me/OxchangeFinance"
  //                           rel="noreferrer"
  //                           title="Telegram"
  //                           target="_blank"
  //                         >
  //                           <FaTelegramPlane />
  //                         </a>
  //                       </li>
  //                       <li>
  //                         <a
  //                           href="mailto:mailto:hello@hashfair.io"
  //                           rel="noreferrer"
  //                           title="Gmail"
  //                           target="_blank"
  //                         >
  //                           <FaRegEnvelope />
  //                         </a>
  //                       </li>
  //                       <li>
  //                         <a
  //                           href="https://twitter.com/oxchangefinance"
  //                           rel="noreferrer"
  //                           title="Twitter"
  //                           target="_blank"
  //                         >
  //                           <FaTwitter />
  //                         </a>
  //                       </li>
  //                       <li>
  //                         <a
  //                           href="https://medium.com/@hashfair.io"
  //                           rel="noreferrer"
  //                           title="Medium"
  //                           target="_blank"
  //                         >
  //                           <FaMediumM />
  //                         </a>
  //                       </li>
  //                       <li>
  //                         {/* ....... */}
  //                         <a
  //                           href="https://www.instagram.com/oxchangefinance/"
  //                           rel="noreferrer"
  //                           title="Instagram"
  //                           target="_blank"
  //                         >
  //                           <FaInstagram />
  //                         </a>
  //                       </li>
  //                       <li>
  //                         <a
  //                           href="https://www.reddit.com/r/oxchange/"
  //                           rel="noreferrer"
  //                           title="Reddit"
  //                           target="_blank"
  //                         >
  //                           <FaRedditAlien />
  //                         </a>
  //                       </li>
  //                       <li>
  //                         <a
  //                           href="https://www.facebook.com/oxchangefinanceofficial/"
  //                           rel="noreferrer"
  //                           title="Facebook"
  //                           target="_blank"
  //                         >
  //                           <FaFacebookF />
  //                         </a>
  //                       </li>
  //                     </ul>
  //                   </div>
  //               </div>
  //               <div className="col-12 col-md-6">
  //                 <div className="d-flex align-items-center justify-content-center mt-2 mt-md-5 text-white">
  //                   <p>
  //                     Lorem, ipsum dolor sit amet consectetur adipisicing elit.
  //                     Fugiat, dignissimos ullam maxime quibusdam adipisci
  //                     maiores, dolores doloremque consequatur, suscipit ipsam
  //                     quae dolorum veniam nobis reiciendis incidunt dolore harum
  //                     illo cum?
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //           </CAccordionBody>
  //         </CAccordionItem>
  //       </CAccordion>
  //     </div>
  //   </section>
  // );
};

export default BalanceOverview;
