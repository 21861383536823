import React, { useState } from 'react'
import './RefDetails.css'
import { useClaimGraph } from '../../hooks/useClaimGraph';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import History from './History';

const ClaimDetails = () => {

    const [value, setValue] = useState(0);
    const { data: { hfgClaimed, hfgBonus } } = useClaimGraph()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <section className='mt-5'>
                <div className='d-flex justify-content-center'>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile="mobile"
                    >
                        <Tab label="HFG Claim" />
                        <Tab label="Referral Bonus" />
                    </Tabs>
                </div>
                <div>

                    {value === 0 &&
                        <History props={hfgClaimed} prop2={"HFG Claim History"} />}
                    {value === 1 &&
                        <History props={hfgBonus} prop2={"Referral Bonus History"} />}
                </div>
            </section>

        </>

    )
}

export default ClaimDetails