import React from 'react'
import './SaleDetailList.css'

const SaleDetailList = () => {

    return (
        <>
            <section className="saleDetails">
                <div className="oc_container">
                    <div className="title">
                        <h2>Sale Details</h2>
                    </div>
                    <div className="saleDetailsList">
                        <p>
                            We're thrilled to announce that our unique HashFair Seed Sale is Live Now! This is a one-of-a-kind opportunity that comes with only a single round of sale. It's time to gear up and prepare your wallets for an exciting journey ahead, at a price of <b>$0.075</b>
                        </p>

                        <p>
                            The HashFair Seed Sale is your golden ticket to secure HFG tokens at an early stage. This is a rare chance to become part of the HashFair community and contribute to our vision of revolutionizing the blockchain space.

                        </p>

                        <p>
                            Remember, there's only one round, and once it's over, it's over. Don't miss out on this exclusive opportunity to fill your wallets with HFG tokens. Stay tuned for more details on the sale date and process.

                            Get ready to be part of the HashFair Seed Sale - Your gateway to the future of blockchain!
                        </p>
                    </div>
                </div>
            </section>
            {/* Show Details */}

        </>

    )
}

export default SaleDetailList