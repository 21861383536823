import React from 'react'
import PublicSale from '../../Components/PublicSale/PublicSale'
import Projects from '../../Components/Projects/Projects'
import Faqs from '../../Components/Faqs/Faqs'
import SaleDetailList from '../../Components/SaleDetailList/SaleDetailList'
// import SaleDetails from '../../Components/SaleDetailList/SaleDetailList'

const Sale = () => {
    
    // const pull_data = (data) => {
    //   return data
    //   }
    //func={pull_data}
    return (
        <>
           
            <PublicSale  />  
            <SaleDetailList/>
            <Projects />
            <Faqs />
        </>
    )
}

export default Sale