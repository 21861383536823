import React from 'react'
import Faqs from '../../Components/Faqs/Faqs'
import BalanceOverview from '../../Components/BalanceOverview/BalanceOverview'
import Banner from '../../Components/Banner/Banner'
import Statisics from '../../Components/Statisics/Statisics'
import BuySell from '../../Components/ButSell/BuySell'
import ClaimSec from '../../Components/Claim/Claim'

const SaleDetails = () => {

    return (
        <>
            {/* <PublicSale /> */}
            <Banner />
            <BuySell />
            <ClaimSec/>
            {/* <BuyOc /> */}
            <Statisics />
            <BalanceOverview />
            <Faqs />
        </>
    )
}

export default SaleDetails