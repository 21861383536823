import React from 'react'
import { useEffect } from 'react'
import './SaleCountdown.css'


function SaleCountdown() {

  useEffect(() => {
    console.clear();

    function CountdownTracker(label, value) {

      var el = document.createElement('span');

      el.className = 'flip-clock__piece';
      el.innerHTML = '<b class="flip-clock__card card"><b class="card__top"></b><b class="card__bottom"></b><b class="card__back"><b class="card__bottom"></b></b></b>' +
        '<span class="flip-clock__slot">' + label + '</span>';

      this.el = el;

      var top = el.querySelector('.card__top'),
        bottom = el.querySelector('.card__bottom'),
        back = el.querySelector('.card__back'),
        backBottom = el.querySelector('.card__back .card__bottom');

      this.update = function (val) {
        val = ('0' + val).slice(-2);
        if (val !== this.currentValue) {

          if (this.currentValue >= 0) {
            back.setAttribute('data-value', this.currentValue);
            bottom.setAttribute('data-value', this.currentValue);
          }
          this.currentValue = val;
          top.innerText = this.currentValue;
          backBottom.setAttribute('data-value', this.currentValue);

          this.el.classList.remove('flip');
          void this.el.offsetWidth;
          this.el.classList.add('flip');
        }
      }

      this.update(value);
    }

    function getTimeRemaining(endtime) {
      var t = Date.parse(endtime) - Date.parse(new Date());
      return {
        'Total': t,
        'Days': Math.floor(t / (1000 * 60 * 60 * 24)),
        'Hours': Math.floor((t / (1000 * 60 * 60)) % 24),
        'Minutes': Math.floor((t / 1000 / 60) % 60),
        'Seconds': Math.floor((t / 1000) % 60)
      };
    }

    function Clock(countdown, callback) {
      countdown = countdown ? new Date(Date.parse(countdown)) : false;
      callback = callback || function () { };

      var updateFn = getTimeRemaining;

      this.el = document.createElement('div');
      this.el.className = 'flip-clock';

      var trackers = {},
        t = updateFn(countdown),
        key, timeinterval;

      for (key in t) {
        if (key === 'Total') { continue; }
        trackers[key] = new CountdownTracker(key, t[key]);
        this.el.appendChild(trackers[key].el);
      }

      var i = 0;
      function updateClock() {
        timeinterval = requestAnimationFrame(updateClock);

        // throttle so it's not constantly updating the time.
        if (i++ % 10) { return; }

        var t = updateFn(countdown);
        if (t.Total < 0) {
          cancelAnimationFrame(timeinterval);
          for (key in trackers) {
            trackers[key].update(0);
          }
          callback();
          return;
        }

        for (key in trackers) {
          trackers[key].update(t[key]);
        }
      }

      setTimeout(updateClock, 500);
    }
    var initialDeadline = new Date(1694942999000);
    var deadline = new Date(initialDeadline); // Create a copy of the initial deadline

    function restartCountdown() {
      // Add 30 days to the deadline when the countdown is complete
      deadline.setDate(deadline.getDate() + 30);
      var c = new Clock(deadline, function () {
        // Do something when the countdown is complete (if needed)
        restartCountdown(); // Restart the countdown
      });
      page_timer.innerHTML = ''; // Clear the previous countdown
      page_timer.appendChild(c.el);
    }

    // var deadline = new Date(1694942999000);
    // var deadline = new Date(Date.parse(new Date('2023/09/10')));
    var c = new Clock(deadline, function () {
      restartCountdown()
      /* Do something when countdouwn is complete */
});
    var page_timer = document.getElementById('flip_timer');
    page_timer.appendChild(c.el);

    /*
    var clock = new Clock();
    document.body.appendChild(clock.el);
    */


  }, [])

  return (
    <div className="content-wrapper p-0">
      <section class="main-clock-area">
        <div class="clock-title">
          {/* <h3>Time Remaining</h3> */}
        </div>
        <div class="main-clock">
          <div id='flip_timer'></div>
        </div>
      </section>
    </div>
  )
}

export default SaleCountdown