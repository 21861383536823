import React, { useEffect, useState } from "react";
import "./Projects.css";

import { RoundPage } from "./RoundPage";
import { hardCap, saleTenure } from "../../config";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useContractData } from "../../hooks/useContractData";
import { localeFixed } from "../../config/helpers";
import { formatEther, formatUnits } from "viem";
import ReactPlayer from 'react-player'
import Lottie from "react-lottie-player";
import hashfairCoin from '../../Assets/images/coin-animation.lottie.json'

const Projects = () => {

  const [hfgSold, setHfgSold] = useState(0);
  const [usdRaised, setUsdRaised] = useState(0);
  const [usdcRaised, setUsdcRaised] = useState(0);
  const [usdtRaised, setUsdtRaised] = useState(0);

  const [progress, setProgress] = useState(0)
  const [countdown, setCountdown] = useState(0)

  const { result: data } = useContractData()

  useEffect(() => {
    if (data) {
      setUsdRaised(formatUnits(data.totalRaised, 6))

      const sale = data
      const saleStart = sale.started
      const hfRaised = sale.hfSold
      const hfRaisedOnReferral = sale.hfSoldReferral

      const endDate = new Date(saleStart * 1000 + saleTenure * 1000)
      const totalRaised = parseFloat(formatEther(hfRaised.add(hfRaisedOnReferral)))
      const progress = (totalRaised / hardCap) * 100

      setCountdown(endDate)
      setProgress(progress)
      setHfgSold(totalRaised)
      setUsdcRaised(parseFloat(formatUnits(sale.usdcRaised, 6)))
      setUsdtRaised(parseFloat(formatUnits(sale.usdtRaised, 6)))
    }
  }, [data, setHfgSold, setUsdRaised, setCountdown, setProgress])

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: {
      items: 3,

      itemsFit: "fill",
    },
  };

  //

  return (
    <section className="projects">
      <div className="oc_container">
        {/* List Box */}
        <div className="row mb-5 listbox justify-content-center mx-auto">
          <div className="row countBox justify-content-center align-items-center">
            <div className="col-lg-3 col-sm-6 text-center">
              <div className="infoBox">
                <h2>Sale Statistics</h2>
                <p>Overall statistics for HFG Sale</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 text-center">
              <div className="countinbox">
                <svg
                  baseProfile="tiny"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  overflow="visible"
                  className="sc-ff25dd6a-0 bDcPbN"
                >
                  <g>
                    <path
                      fill="currentColor"
                      d="M24 8c0-.6-.2-1.2-.5-1.7s-.8-.9-1.4-1.1c-.6-.2-1.2-.3-1.8-.1-.6.1-1.1.5-1.5.9L12 3.2V3c0-.8-.3-1.6-.9-2.1S9.8 0 9 0 7.4.3 6.9.9 6 2.2 6 3c0 .6.2 1.3.6 1.8L3.7 9.1C3.5 9 3.2 9 3 9c-.6 0-1.1.2-1.6.5s-.9.8-1.1 1.3c-.3.5-.4 1.1-.3 1.6.1.6.4 1.1.7 1.6.4.4.9.8 1.4.9.6.2 1.1.1 1.7 0 .6-.2 1-.5 1.4-.9.4-.4.6-1 .7-1.6l11.6-2.6-7.2 6.6c-.3-.3-.8-.4-1.3-.4-.6 0-1.3.2-1.8.6-.5.4-.9.9-1.1 1.5-.2.7-.1 1.3.1 1.9.2.6.6 1.1 1.2 1.5.5.4 1.1.5 1.8.5.6 0 1.3-.3 1.8-.7.5-.4.8-1 1-1.6.2-.6.1-1.3-.2-1.9l7.8-7.2-1.1 7.4c-.7.1-1.3.5-1.8 1-.5.6-.7 1.2-.7 2 0 .7.3 1.4.7 1.9s1.1.9 1.8 1 1.4 0 2-.4c.6-.4 1.1-.9 1.3-1.6.2-.7.2-1.4 0-2.1-.3-.7-.8-1.2-1.4-1.6l1.1-7.4c.7-.1 1.3-.5 1.8-1 .5-.4.7-1.1.7-1.8zM11.2 5l6.5 2.7-12.2 2.7c0-.1-.1-.1-.1-.2l2.9-4.3c.2.1.5.1.7.1.4 0 .8-.1 1.2-.3.4-.1.7-.4 1-.7z"
                    ></path>
                  </g>
                </svg>
                <p>Total HFG Sold</p>
                {/* <h3>{localeFixed(486944+Number(totalocsold))}</h3> */}
                <strong>
                  {/* {
                    isLoading ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : ( */}
                  {localeFixed(hfgSold ?? "0")}
                  {/* ) */}
                  {/* } */}
                </strong>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 text-center">
              <div className="countinbox">
                <svg
                  baseProfile="tiny"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  overflow="visible"
                  className="sc-5f2a7416-0 jbewcx"
                >
                  <g fill="currentColor">
                    <path d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path>
                    <path
                      fill-opacity="0.302"
                      d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                    ></path>
                    <path d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path>
                    <path
                      fill-opacity="0.302"
                      d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"
                    ></path>
                    <path d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path>
                    <path
                      fill-opacity="0.302"
                      d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"
                    ></path>
                    <path d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                    <path
                      fill-opacity="0.302"
                      d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                    ></path>
                    <path d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path>
                    <path
                      fill-opacity="0.302"
                      d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"
                    ></path>
                    <path d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path>
                    <path
                      fill-opacity="0.302"
                      d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                    ></path>
                  </g>
                </svg>
                <p>Total USD Raised</p>
                {/* <h3>{localeFixed(229718 +Number(USDraised))}</h3> */}
                <strong>
                  {/* ${
                    isLoading ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : ( */}
                  ${localeFixed(usdRaised ?? "0")}
                  {/* ) */}
                  {/* } */}
                </strong>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 text-center">
              <div className="countinbox">
                <svg
                  baseProfile="tiny"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  overflow="visible"
                  className="sc-ca212e21-0 gHYayU"
                >
                  <g fill="currentColor">
                    <path d="M12 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path>
                    <path d="M19 4V1.5c0-.8-.7-1.5-1.5-1.5S16 .7 16 1.5V4h-1V1.5c0-.8-.7-1.5-1.5-1.5S12 .7 12 1.5V4h-1V1.5C11 .7 10.3 0 9.5 0S8 .7 8 1.5V4H7V1.5C7 .7 6.3 0 5.5 0S4 .7 4 1.5V4H0v14h4.3c.9 3.4 4 6 7.7 6s6.8-2.6 7.7-6H24V4h-5zm3 12h-2V8c-2.2 0-4 1.8-4 4v3c-1 0-1.9.4-2.6 1-1.4 1.3-1.9 3-1.9 3s-.5-1.3-.3-3H2V6h20v10z"></path>
                  </g>
                </svg>
                <p>HFG Price</p>
                <strong>
                  $0.075
                </strong>
              </div>
            </div>
          </div>
        </div>
        {/* List */}
        <div className="row listcurrent justify-content-center align-items-center mx-auto pt-5">
          <div className="col-12 col-md-6">
            <div className="infoBox text-center mt-md-0 mb-3">
              <h2>Current Sale</h2>
              <p>Overall statistics for Current HFG Sale</p>
            </div>
            <div className="imageBox">
              {/* <img src={OCAnim} alt="OX Change" width="500" height="500" /> */}
              {/* <ProgressiveImage delay={0} src={OCAnimOrig} placeholder={OCAnimPlace}>
                {(src) => <img src={src} alt="OX Change" />}
              </ProgressiveImage> */}
              {/* <ReactPlayer
                className='react-player currentSaleVideo'
                url='https://ik.imagekit.io/imgdata/hashfair/coin-animation.mp4?updatedAt=1687960408052'
                width='100%'
                height='100%'
                muted
                loop
                playing="true"
                playsinline="true"
              /> */}
              <Lottie
                loop
                className='react-player currentSaleVideo'
                animationData={hashfairCoin}
                play
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <RoundPage
              hfgRaised={hfgSold}
              countdown={countdown}
              progress={progress}
              usdcRaised={usdcRaised}
              usdtRaised={usdtRaised}
            />
          </div>
        </div>
        <div className="row listBox mt-3 mb-3">
          <AliceCarousel
            mouseTracking
            infinite
            autoPlayInterval={1000}
            animationDuration={1500}
            disableButtonsControls
            responsive={responsive}
            autoPlay
          >
            {/* {rounds.map((value, idx) => {
              return (
                <div key={idx}>
                  <RoundPage
                    address={value.address}
                    price={value.price}
                    ocAllotted={value.ocAllotted}
                    startTime={value.startTime}
                    idx={idx + 1}
                  />
                </div>
              );
            })} */}
          </AliceCarousel>
        </div>
      </div>
    </section>
  );
};

export default Projects;
