import React, { useEffect, useState } from "react";
import "./Statisics.css";
import "react-toastify/dist/ReactToastify.css";

//core ui imports
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import { localeFixed } from "../../config/helpers";
import { hardCap } from "../../config";
import { useContractData } from "../../hooks/useContractData";
import { formatEther, formatUnits } from "ethers/lib/utils";
import UserStatistics from "./UserStatistics";
import { useAddress } from "../../hooks/useAddress";

const Statisics = () => {

  const { address } = useAddress()

  const [hfgSold, setHfgSold] = useState(0);
  const [usdRaised, setUsdRaised] = useState(0);
  const [usdcRaised, setUsdcRaised] = useState(0);
  const [usdtRaised, setUsdtRaised] = useState(0);

  // const [_, setProgress] = useState(0)
  // const [__, setCountdown] = useState(0)
  
  const {result: data, isLoading} = useContractData()

  useEffect(() => {
    if (!isLoading && data) {
      setUsdRaised(formatUnits(data.totalRaised, 6))
      
      const sale = data
      // const saleStart = sale.started
      const hfRaised = sale.hfSold
      const hfRaisedOnReferral = sale.hfSoldReferral
      
      // const endDate = new Date(saleStart * 1000 + saleTenure * 1000)
      const totalRaised = parseFloat(formatEther(hfRaised.add(hfRaisedOnReferral)))
      // const progress = (totalRaised / hardCap) * 100
      
      // setCountdown(endDate)
      // setProgress(progress)
      setHfgSold(totalRaised)
      setUsdcRaised(parseFloat(formatUnits(sale.usdcRaised, 6)))
      setUsdtRaised(parseFloat(formatUnits(sale.usdtRaised, 6)))
    }
  }, [data, setHfgSold, setUsdRaised, isLoading])

  return (
    <>
      <section className="statisics">
        <div className="oc_container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="boxinner leftinfo">
                <CAccordion flush>
                  <CAccordionItem itemKey={1}>
                    <CAccordionHeader>
                      {" "}
                      <h2>STATISTICS</h2>{" "}
                      <div className="row justify-content-center align-items-center w-100 mx-auto">
                        <div className="col-12 col-md-3 text-center stat-info">
                          <div className="m-0 inputlabel">
                            <p>Sale Supply</p>
                            <strong>{localeFixed(hardCap)} HFG</strong>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 text-center stat-info">
                          <div className="m-0 inputlabel">
                            <p>HFG Sold</p>
                            <strong>
                              {localeFixed(hfgSold)} HFG
                            </strong>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 text-center stat-info">
                          <div className="m-0 inputlabel">
                            <p>Total Raised</p>
                            <strong>
                              ${localeFixed(usdRaised)}
                            </strong>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 text-center stat-info">
                          <p className="m-0 viewbtn d-flex align-items-center justify-content-center mx-auto mx-md-0 ms-md-auto">
                            View Details{" "}
                            <span className="arrow d-inline-block ms-2">
                              {" "}
                              &#10095;
                            </span>
                          </p>
                        </div>
                      </div>
                    </CAccordionHeader>
                    <CAccordionBody>
                      <div className="row">
                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>{localeFixed(hardCap)}</p>
                            <p>Sale Supply</p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>${localeFixed(hardCap * 0.075)}</p>
                            <p>HardCap</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>{localeFixed(usdtRaised)}</p>
                            <p>USDT Raised</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>{localeFixed(usdcRaised)}</p>
                            <p>USDC Raised</p>
                          </div>
                        </div>
                      </div>
                    </CAccordionBody>
                  </CAccordionItem>
                </CAccordion>
              </div>
            </div>
            { address && <div className="col-md-12 col-sm-12">
              <div className="boxinner rightinfo">
                <UserStatistics />
              </div>
            </div> }
          </div>
        </div>
      </section>
    </>
  );
};

export default Statisics;
