import { useEffect, useMemo, useState } from "react";

export const useAddress = () => {
    const [address, setAddress] = useState(null)

    useEffect(() => {
        (
            async () => {
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setAddress(accounts[0])
                } catch(err) {
                    setAddress(null)
                }
            }
        )()
    }, [setAddress])

    useEffect(() => {
        const inter = setInterval(
            async () => {
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setAddress(accounts[0])
                } catch(err) {
                    setAddress(null)
                }
            },
           15000
        )

        return () => clearInterval(inter)
    }, [setAddress])

    return useMemo(() => {
        return {
            address
        }
    }
    , [address])
}