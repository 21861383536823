import './Claim.css'
import SaleCountdown from '../SaleCountdown/SaleCountdown';
import { localeFixed } from '../../config/helpers';
import { ClaimHfg, ClaimNft, useUserData } from "../../hooks/useContractData";
import { useNavigate } from 'react-router';


const ClaimSec = () => {

    const navigate = useNavigate()


    // const [hfgBought, setHfgBought] = useState(0)
    // const [referralBonus, setReferralBonus] = useState(0)
    // const [totalInvested, setTotalInvested] = useState(0)

    const { userInfo, hfBonusClaimed, hfClaimed } = useUserData()

    // useEffect(() => {
    //     if (userInfo) {
    //         setHfgBought(formatUnits(userInfo.hfBought, 18))
    //         setReferralBonus(formatUnits(userInfo.hfBonus, 18))
    //         setTotalInvested(formatUnits(userInfo.totalSpent, 6))
    //     }
    // }, [userInfo])


    return (
        <section className="buyOc" id='buySellSection'>
            <div className="oc_container">
                <div className="title">
                    <h2>    </h2>
                </div>
                {/* <SaleCountdown /> */}
                <br />
                <br />
                <div className="row oter">
                    <div className="col-md-12 col-sm-12 mb-4">
                        <div className="row justify-content-center align-items-center w-100 mx-auto">
                            <div className="col-12 col-md-3 text-center stat-info">
                                <div className="buyOcbox">
                                    <div className="Claimdetails">

                                        <div className="m-0 inputlabel">
                                            <p>HFG Claimed</p>
                                            <strong>  {localeFixed(hfClaimed)} HFG</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-3 text-center stat-info">
                                <div className="buyOcbox">
                                    <div className="Claimdetails">
                                        <div className="m-0 inputlabel">
                                            <p>USD Invested</p>
                                            <strong>
                                                ${localeFixed(totalInvested)}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-12 col-md-3 text-center stat-info">
                                <div className="buyOcbox">
                                    <div className="Claimdetails">
                                        <div className="m-0 inputlabel">
                                            <p>Refer Bonus Claimed</p>
                                            <strong>
                                                {localeFixed(hfBonusClaimed)} HFG
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="buttonGrp">
                            <button class="oc_btn" onClick={ClaimHfg} >
                                Claim HFG
                            </button>
                            <button class="oc_btn" onClick={ClaimNft}>
                                Claim NFT
                            </button>
                            <button className="oc_btn" onClick={() => navigate("/claimdetails")} >Claim History</button>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default ClaimSec