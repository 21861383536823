export const localeFixed = (value, fixed=2) => {
    return parseFloat(parseFloat(value).toFixed(fixed)).toLocaleString()
}


export function dateCon(params) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate

}