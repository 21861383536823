import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { formatEther } from "viem";
import { useAddress } from "./useAddress";
import { dateCon } from "../config/helpers";

export const useClaimGraph = () => {
    const [data, setData] = useState({
        hfgClaimed: [],
        hfgBonus: []

    })
    const { address } = useAddress()

    const fetchGraphData = useCallback(async () => {
        try {
            if (!address) return

            const options = {
                method: 'POST',
                url: graphQlUrl,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        hfClaimeds(where:{user:"${address}"}) {
                            id
                            user
                            amount
                            timestamp
                          }
                          hfBonusClaimeds(where:{user:"${address}"}) {
                            id
                            user
                            amount
                            timestamp
                          }
                      
                    }`
                }
            };
            const response = await axios.request(options);
            const res = response.data;

            const { hfBonusClaimeds, hfClaimeds } = res.data

            const hfgClaimed = hfClaimeds?.map((referral) => {
                return {
                    ...referral,
                    amount: parseFloat(formatEther(referral.amount)),
                    timestamp: dateCon(referral.timestamp),
                }
            })

            const hfgBonus = hfBonusClaimeds?.map((referral) => {
                return {
                    ...referral,
                    amount: parseFloat(formatEther(referral.amount)),
                    timestamp: dateCon(referral.timestamp),
                }
            })


            setData({
                hfgClaimed,
                hfgBonus
            })
        } catch (err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 8000);

        return () => clearInterval(inter)
    }, [fetchGraphData])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData])

    return useMemo(() => {
        return {
            data
        }
    }, [data])
}