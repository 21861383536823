import React, { useCallback, useEffect, useState } from 'react'
import logo from '../../Assets/images/logo.gif'
import './header.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { FaSignOutAlt } from "react-icons/fa";
import { connector, defaultNetwork, url } from '../../config'
import ConnectWallet from '../ConnectWallet/ConnectWallet'
import { useConnect, useSwitchNetwork } from 'wagmi'

import { useAddress } from '../../hooks/useAddress';

const notify = () => toast.success('Copied Successfully!')

const Header = () => {
  const [chain, setChain] = useState(0)
  const { connectAsync } = useConnect()

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: defaultNetwork
  })

  const [address, setAccounts] = useState(null)
  const { address: walletAddress } = useAddress()
  const doConnectProfile = useCallback(async() => {
    if (window.ethereum) {
      setChain(parseInt(window.ethereum.chainId))
    }
    try {
      connectAsync?.({connector: connector, chainId: defaultNetwork}).then(() => {}).catch((err) => {
      })
      setAccounts(walletAddress)
    } catch(err) {
      setAccounts(null)
    }
  }, [walletAddress, connectAsync])

  useEffect(() => {
    const intervalId = setInterval(() => {

      // onClickConnect()
      doConnectProfile()
      
    }, 8000)
    return () => clearInterval(intervalId); //This is important
  },[doConnectProfile, walletAddress]);

  useEffect(() => {
    doConnectProfile()
  }, [doConnectProfile])

  async function disconnectwallet() {

    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
    setAccounts()
  }
  

  return (
    <>
      <nav className="navbar">
        <div className="navbar_inner oc_row space-between align-items-center">
          <div className="logo">
            <a href={url} target="_blank" rel='noreferrer'>
              <img src={logo} alt="logo" width="140" height="140" />
            </a>
          </div>


          <div className={`nav-elements`}>
          </div>
          <div className="balancebox">
            <ul>
              {address && <>
                {/* <li><strong>{localeFixed(ocBal)}</strong>HFG</li> */}
                { chain !== defaultNetwork && <li onClick={() => {
                  switchNetworkAsync?.().then(() => {}).catch(() => {})
                }} style={{backgroundColor: "#bc4242", padding: "10px", borderRadius: "20px", cursor: "pointer"}}>
                  Wrong Network
                </li> }
                <li onClick={notify}>
                  <CopyToClipboard text={address}>
                    <span> {address && <> {address.substring(0, 3)} ... {address.slice(-3)}</>}</span>
                  </CopyToClipboard>
                </li>
                <li>
                  <span className='logoutSn' onClick={() => {
                    disconnectwallet()
                  }}>Disconnect<FaSignOutAlt />  </span>
                </li>
              </>
              }
              {!address &&
                <li>
                  {/* <span> <ConnectWallet /></span> */}
                  <span onClick={doConnectProfile}><div><ConnectWallet /></div>  </span>
                </li> 
                // <li> <span className='logoutSn' onClick={disconnectwallet}>Disconnect Wallet<FaSignOutAlt style={{ marginLeft: "3px" }} />  </span></li>
              }
            </ul>
          </div>
        </div>
      </nav>
      {/* <Toaster
        position="bottom-right"
        reverseOrder={false}
      /> */}
    </>
  )
}

export default Header