import { useCallback, useEffect, useState } from "react";
import { localeFixed } from "../../config/helpers";
import Pagination from '@mui/material/Pagination';

export default function History({ props, prop2 }) {

    const [pagination, setPagination] = useState([]);

    const pageHandler = useCallback(() => {
        const PageCount = Math.ceil(Number(props.length) / 10)
        setPagination(PageCount)
    }, [props])


    //  Code for pagination 
    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }


    useEffect(() => {
        pageHandler()
    }, [pageHandler])




    return (<>
        <section className="mt-5 refDetails height">
            <div className="oc_container">
                <div className="title">
                    <h2>{prop2}</h2>
                </div>

                <div className="hisDetailsList">
                    <div className='table-responsive'>
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Date</th>
                                    <th>Bonus Amount (HFG)</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props ? props.slice(indexOfFirst, indexOfLast).map((pack, i) => (
                                    <tr key={i}>
                                        <td>{i + indexOfFirst + 1}</td>
                                        <td>{pack.timestamp}</td>
                                        <td>{localeFixed(pack.amount)} HFG</td>
                                    </tr>
                                )) : <>
                                    <tr>
                                        <td colSpan={4}><p className='text-center m-4'>No Data Found</p></td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {props.length < 11 ?
                ''
                : <>
                    <Pagination className='myPagination' onChange={pagechange} count={pagination} />
                </>
            }
        </section>
    </>)
}