import React from "react";
import "./Projects.css";
import Countdown from "react-countdown";
import { hardCap } from "../../config";
import { Link } from "react-router-dom";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>You missed it!</span>;
  } else {
    // Render a countdown
    return <span>{days}d:{hours}h:{minutes}m:{seconds}s</span>;
  }
};


// url5
export const RoundPage = ({hfgRaised, countdown, progress, usdcRaised, usdtRaised}) => {

  return (
    <div className="col-12 pt-5" id="buySection">
      <div className={`listBoxInner`}>
        <div className="infoBox">
          <strong>HFG Seed Sale</strong>
          <p className="subtitle">Fair Launch</p>
          <strong className="mt-3">Hardcap</strong>
          <strong>
            {hardCap.toLocaleString()} HFG
          </strong>
          <p className="progressBar">
            Progress {progress.toFixed(2)}%
          </p>
          <div className="progresLine"><span style={{ width: `${progress}%` }}></span></div>
          {/* ${isFullIncluded(props.address) ? '100' : totalOCSold * 100 / roundOCLimit} */}
          <div className="row tableBox">
            <p className="ttl">
              {Number(hfgRaised.toFixed(2)).toLocaleString()} HFG
              <span>
                {hardCap.toLocaleString()} HFG
              </span>
            </p>
            <p> HFG Price: <span> $0.075</span></p>
            <p>
              HFG Sold:
              <span>
                {Number(hfgRaised.toFixed(2)).toLocaleString()} HFG
              </span>
            </p>
            <p>
              USDC Raised:
              <span>
                {Number(usdcRaised.toFixed(2)).toLocaleString()} USDC
              </span>
            </p>
            <p>
              USDT Raised
              <span>
                {Number(usdtRaised.toFixed(2)).toLocaleString()} USDT
              </span>
            </p>
          </div>
          <div className="dividerLine"></div>
          <div className="row saleTineView">

            {/* <p className="timesSale">
              Sale Ongoing
              <br />
              { countdown !== 0 && <Countdown date={countdown} renderer={renderer} /> }
            </p> */}
            <Link to={`/saledetails`} className="btnView">View</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
