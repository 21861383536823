import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";

const data = {
    title: "Frequently Asked Questions",
    rows: [
        {
            title: "Which wallet should I use to participate in HashFair Games?",
            content: `HashFair Games is compatible with various crypto wallets. However, we recommend using Trust Wallet or Metamask Wallet for the best user experience and seamless integration with the platform.`,
        },
        {
            title: "Will I Get referral on referring a user to buy HFG?",
            content: `You will receive a referral when a user uses your referral link & buys HFG in seed round. `,
        },
        // {
        //     title: "When does the HashFair Games Seed Round begin and how long does it last?",
        //     content: `The HashFair Games Seed Round starts on July 1st, 2023.`,
        // },
        {
            title: "What can I expect from the Seed Round of HashFair Games?",
            content: `During the Seed Round, playable tokens will be distributed to participants. It serves as an initial opportunity to contribute and acquire tokens for engagement within the HashFair Games platform.`,
        },
        {
            title: "Are there referral bonuses available during the Seed Round?",
            content: `Yes, there is a referral program with three levels. The 1st level offers a referral bonus of 2%, while the 2nd and 3rd levels offer 1% each. Additionally, for each successful referral, you will receive $5 worth of HUSD tokens as a bonus.`,
        },
        {
            title: "Are there any requirements to claim the referral bonuses?",
            content: `Yes, to claim the referral bonuses, you need to meet the minimum criteria of contribution in the Seed Round. The exact contribution amount required may vary, so it is advisable to refer to the official guidelines or documentation provided by HashFair Games for the specific requirements.`,
        },
        {
            title: "Are there additional rewards for specific contribution amounts in the Seed Round?",
            content: `Yes, participants who complete a contribution of $10,000 or $50,000 during the Seed Round will receive additional rewards. A contribution of $10,000 earns one free NFT, while a contribution of $50,000 earns two NFTs—one worth $10,000 and another worth $50,000.`,
        },
        {
            title: "What is the vesting period?",
            content: `The vesting period is a specified timeframe during which tokens or assets are gradually released to participants based on predetermined conditions.`,
        },
        {
            title: "How does the vesting period work in HashFair?",
            content: `In HashFair, the vesting period ensures a controlled release of tokens and NFTs. After the completion of the seed round, there is a specific timeline for the release of these assets.`,
        },
        {
            title: "When will the NFTs be released after the completion of the seed round?",
            content: `The NFTs will be released 30 days after the completion of the seed round. This allows for a period of time to organize and finalize the distribution process.`,
        },
        {
            title: "What about the release of tokens after the completion of the seed round?",
            content: `The tokens will be released gradually over a span of 60 days after the completion of the seed round. Each month, 10% of the total allocated tokens will be made available to participants.`,
        },
        {
            title: "Will participants have any restrictions during the vesting period?",
            content: `Yes, participants will have certain restrictions on the transfer or sale of tokens during the vesting period. These restrictions are in place to ensure a balanced and controlled token distribution.`,
        },
        {
            title: "What about referral earnings? When will they be accessible?",
            content: `Referral earnings will become accessible after the vesting period, which is also 60 days from the sale's completion. Participants can then start receiving their referral earnings.`,
        },
        {
            title: "How will the referral earnings be released during the vesting period?",
            content: `Similar to the HFG tokens, referral earnings will be released in equal portions over a period of 10 months. Each month, participants will receive 10% of their referral earnings.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#ffffff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#ffffff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <section className="faqs">
            <div className="oc_container">
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs