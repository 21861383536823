import React, { useEffect, useState } from 'react'
import './BuySell.css'
import ScrollAnimation from 'react-animate-on-scroll';
import { defaultNetwork, hardCap, ocToken, oracleAddress } from '../../config';

import { CProgress, CProgressBar } from '@coreui/react';
import { useContractData } from '../../hooks/useContractData';
import { formatEther } from 'viem';
import { useSwitchNetwork } from 'wagmi';
import { localeFixed } from '../../config/helpers';
import { addToken } from '../../utils/addToken';


const BuySell = () => {
  const [progress, setProgress] = useState(0)
  const [hfgSold, setHfgSold] = useState(0)
  
  const {result: data, isLoading} = useContractData()

  const switchToPolygon = useSwitchNetwork({
    chainId: defaultNetwork
  })

  useEffect(() => {
    if (!isLoading && data) {
      const sale = data
      const hfRaised = sale.hfSold
      const hfRaisedOnReferral = sale.hfSoldReferral
      
      const totalRaised = parseFloat(formatEther(hfRaised.add(hfRaisedOnReferral)))
      const progress = (totalRaised / hardCap) * 100
      
      setProgress(progress)
      setHfgSold(totalRaised)
    }
  }, [data, isLoading, setProgress, setHfgSold])

  return (
    <section className="buySell">
      {/* <Toaster
        position="bottom-right"
        reverseOrder={false}
      /> */}
      <div className="oc_container">
        <div className="row">
          <div className="col-sm-12">
            <div className="buySellbox">
              <ScrollAnimation animateIn='fadeIn'
                initiallyVisible={true}
                animateOnce={true}>
                {/* <div className="fillBox">
                  <span style={{ width: `${progress}%` }}></span>
                </div> */}
                <CProgress className="mb-3">
                  <CProgressBar variant="striped" animated value={progress} title='HFG Seed Sale' />
                </CProgress>
                <div className="oc_row align-items-center space-between">
                  <span>{localeFixed(hfgSold)} HFG SOLD</span>
                  <span>Hardcap</span>
                </div>
                <div class="buttonGrp">
                  <button class="oc_btn" onClick={() => {
                    switchToPolygon.switchNetworkAsync()
                  }} >Add Polygon Mainnet</button>
                  <button class="oc_btn" onClick={() => {
                    addToken({
                      data: {
                        address: ocToken,
                        symbol: 'HFG',
                        decimals: 18,
                        name: "HashFair Games"
                      }
                    })
                  }} >Add HFG To Metamask</button>
                  <a href={`https://polygonscan.com/address/${oracleAddress}`} rel="noreferrer" style={{ textDecoration: "none" }} class="oc_btn" target="_blank">View Smart Contract </a>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuySell