import React from 'react'
import './Faqs.css'
import FaqsRef from "react-faq-component";

const data = {
    title: "Frequently Asked Questions",
    rows: [
        {
            title: "What is the vesting period?",
            content: `The vesting period is a specified timeframe during which tokens or assets are gradually released to participants based on predetermined conditions.`,
        },
        {
            title: "How does the vesting period work in HashFair?",
            content: `In HashFair, the vesting period ensures a controlled release of tokens and NFTs. After the completion of the seed round, there is a specific timeline for the release of these assets.`,
        },
        {
            title: "When will the NFTs be released after the completion of the seed round?",
            content: `The NFTs will be released 30 days after the completion of the seed round. This allows for a period of time to organize and finalize the distribution process.`,
        },
        {
            title: "What about the release of tokens after the completion of the seed round?",
            content: `The tokens will be released gradually over a span of 60 days after the completion of the seed round. Each month, 10% of the total allocated tokens will be made available to participants.`,
        },
        {
            title: "Will participants have any restrictions during the vesting period?",
            content: `Yes, participants will have certain restrictions on the transfer or sale of tokens during the vesting period. These restrictions are in place to ensure a balanced and controlled token distribution.`,
        },
        {
            title: "What about referral earnings? When will they be accessible?",
            content: `Referral earnings will become accessible after the vesting period, which is also 60 days from the sale's completion. Participants can then start receiving their referral earnings.`,
        },
        {
            title: "How will the referral earnings be released during the vesting period?",
            content: `Similar to the HFG tokens, referral earnings will be released in equal portions over a period of 10 months. Each month, participants will receive 10% of their referral earnings.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#ffffff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#ffffff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const FaqsRefs = () => {
    return (
        <section className="faqs">
            <div className="oc_container">
                <FaqsRef
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default FaqsRefs