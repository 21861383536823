import { ethers } from "ethers"
import Web3 from "web3"
import { InjectedConnector } from 'wagmi/connectors/injected'

export const oracleAddress = "0x97B6bC3282d029E77874eFDcF5b7e42B43B16d62"

export const ocToken = "0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf"
export const usdtToken = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
export const usdcToken = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"

export const rpcUrl = "https://delicate-blue-brook.matic.quiknode.pro/5a7dae00f9ce4fbea3ea4bee41ef47ca0b3dc35f/" // https://magical-light-shadow.matic-testnet.quiknode.pro/874457edd3d51f7866cdc0fd8ffb45c191bb5654/" // https://neat-winter-theorem.matic-testnet.quiknode.pro/6da8d3390061ab44f9b6762800871559af07628c/

export const defaultWeb3 = new Web3(rpcUrl)
export const web3Provider = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)

export const url = `https://seed.hashfair.io/`

export const hardCap = 20_000_000
export const userCap = 50_000
export const defaultNetwork = 137

export const saleTenure = 7 * 24 * 60 * 60

export const graphQlUrl = "https://gateway-arbitrum.network.thegraph.com/api/07954754ae20ec2d7d2767afaf8fd317/subgraphs/id/5TKZRDNzP7sP45Fyyb2nepSCvjhpwYmjRgGkasjYyWyZ"

export const connector = new InjectedConnector()